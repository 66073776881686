import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Coupon } from '../_model/pgw/coupon';
import { ExecutePaymentRequestDto } from '../_model/pgw/execute-payment-request-dto';
import { ExecutePaymentResponseDto } from '../_model/pgw/execute-payment-response-dto';
import { CouponRequest } from './../_model/pgw/coupon-request';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class PgwService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    public executePayment(requestDto: ExecutePaymentRequestDto): Observable<ExecutePaymentResponseDto> {
        return this.httpClient.post(this.helper.getPatientUrl(`pgw/payments`), requestDto);
    }

    // http://localhost:9190/booking/eav2/erp/public/promapi/contractors/54/code
    public validateWebCoupon(maskedContractorId: string, maskedSubcontractorId: string, coupon: CouponRequest): Observable<Coupon> {
        return this.httpClient.post(
            this.helper.getPromotionUrl(`contractors/${maskedContractorId}/subcontractors/${maskedSubcontractorId}/code`),
            coupon,
        );
    }
}
