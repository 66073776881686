import { AfterContentInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { slideInAnimation } from './animations';
import { TemplateStyle } from './_enums/template-style.enum';
import { GuidInfo } from './_model/common/guid-info.model';
import { WidgetInfo } from './_model/common/widget-info.model';
import { HelperService } from './_services/helper.service';
import { ParentIframeService } from './_services/parent-iframe.service';
import { BaseState } from './_state/base/base.state';

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
    animations: [
        slideInAnimation,
        // animation triggers go here
    ],
})
export class AppComponent implements OnInit, AfterContentInit {
    title = `app v${environment.VERSION}`;
    private onDestroy$ = new Subject<void>();
    public isMaxWidth: boolean = false;
    public templateStyleClass: string = '';
    // 'm-page--loading-non-block m-page--fluid m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default';

    @ViewChild(ToastContainerDirective) toastContainer: ToastContainerDirective;

    constructor(
        private _router: Router,
        private store: Store,
        private helper: HelperService,
        private titleService: Title,
        private toastrService: ToastrService,
        private parentIframe: ParentIframeService,
    ) {}

    public guid: string;
    public cData: GuidInfo;
    public isGuid: boolean;

    ngOnInit() {
        this.toastrService.overlayContainer = this.toastContainer;
        this.titleService.setTitle(this.title);
        this.store
            .select(BaseState.getWidgetInfo)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((wi: WidgetInfo) => {
                const color: string = wi?.data?.color;
                const templateStye: TemplateStyle = wi?.data?.templateStyle;
                if (templateStye == TemplateStyle.SQUARE) {
                    this.templateStyleClass = 'mf-template-square';
                } else if (templateStye == TemplateStyle.PILL) {
                    this.templateStyleClass = 'mf-template-pill';
                }
                this.isMaxWidth = wi?.data?.widgetMode == 'modal';
                if (wi == undefined) {
                    this.isMaxWidth = true;
                }
                if (color != undefined) {
                    if (color.indexOf('#') > -1) {
                        this.helper.changeAccentColor(`${color}`);
                    } else {
                        this.helper.changeAccentColor(`#${color}`);
                    }
                }
            });
        this._router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                window.scrollTo(0, 0);
            }
        });
    }

    ngAfterContentInit() {
        this.parentIframe.toggleScroll();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    }
}
