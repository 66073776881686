import { EnvironmentModel } from './environment.model';

export const environment: EnvironmentModel = {
    VERSION: require('../../package.json').version,
    production: true,
    RELEASE_STAGE: 'production',
    appUrl: 'https://booking.eambulanta.si',
    recaptchaApiKey: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT',
    service: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        apiPort: '',
        apiUrl: '/patient/api',
        erpApiUrl: '/erp/api',
        communicationapi: '/erp/communicationapi',
    },
    patient: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si',
        port: '',
        url: '/booking/patient/api',
    },
    accounting2: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si',
        port: '',
        url: '/accounting2',
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance/api',
        },
    },
    widget: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/widgetapi',
    },
    erp: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp',
    },
};
