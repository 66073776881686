import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contractor } from './../_model/common/contractor.model';
import { Customer } from './../_model/common/customer.model';
import { GuidInfo } from './../_model/common/guid-info.model';
import { Service } from './../_model/common/service.model';
import { PriceList } from './../_model/reservations/price-list.model';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getContractor(cidmasked: string, isExternal: boolean = false): Observable<Contractor> {
        let url = `access/contractors/${cidmasked}`;
        if (isExternal) {
            url += '?external=true';
        }
        return this.httpClient.get(this.helper.getPatientUrl(url));
    }

    public getService(cidmasked?: string, sidmasked?: string, isExternal: boolean = false): Observable<Service> {
        let url = `access/contractors/${cidmasked}/services/${sidmasked}`;
        if (isExternal) {
            url += '?external=true';
        }
        return this.httpClient.get(this.helper.getPatientUrl(url));
    }

    public getSubcontractorServicePrices(cidmasked: string, serviceidmasked: string): Observable<PriceList[]> {
        return this.httpClient.get(this.helper.getErpUrl(`contractors/${cidmasked}/services/${serviceidmasked}/pricemap`));
    }

    // http://10.230.33.13:8092/booking/v2/assistance/api/public/bs/SUkg4DBqR6qBGXuq3binnws/contractors/cHB-6yBFkBzvhA4shOk-r4M/services/MhlgNvOHh+kOMq77upnWWg==/price

    // http://localhost:8092/booking/v2/assistance/api/public/bs/SUkg4DBqR6qBGXuq3binnws/contractors/cHB-6yBFkBzvhA4shOk-r4M/services/MhlgNvOHh%2BkOMq77upnWWg%3D%3D/price

    // http://10.230.33.13:8092/booking/v2/assistance/api/public/bs/SUkg4DBqR6qBGXuq3binnws/contractors/cHB-6yBFkBzvhA4shOk-r4M/services/MhlgNvOHh+kOMq77upnWWg==/price
    //api/booking/booking/v2/assistance/api/public/bscontractorserviceprice
    public getBSServicePrice(bsidmasked: string, cidmasked: string, serviceidmasked: string): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(`public/bscontractorserviceprice`, 2), {
            maskedBsId: bsidmasked,
            maskedContractorId: cidmasked,
            maskedServiceId: serviceidmasked,
        });
    }

    public getGuidInfo(token: string, isDoctor: boolean = false, isExternal: boolean = false): Observable<GuidInfo> {
        let url: string = '';
        if (isDoctor) {
            url = this.helper.getReservationsUlr(`reservations/${token}?isDoctor=true`); //EPOSVET
        } else {
            url = this.helper.getReservationsUlr(`reservations/${token}?isExternal=${isExternal}`);
        }
        return this.httpClient.get(url);
    }

    public addCustomer(maskedContractorId: string, customer: Customer): Observable<string> {
        return this.httpClient.post(this.helper.getReservationsUlr(`contractors/${maskedContractorId}/customers`), customer, {
            responseType: 'text',
        });
    }

    public getSubcontractors(cidmasked?: string, serviceidmasked?: string): Observable<{ maskedId: string; name: string }[]> {
        //localhost:9090/booking/patient/api/contractors/cHB-6yBFkBzvhA4shOk-r4M/services/MhlgNvOHh%2BkOMq77upnWWg%3D%3D/subcontractors
        http: return this.httpClient.get(this.helper.getReservationsUlr(`contractors/${cidmasked}/services/${serviceidmasked}/subcontractors`));
    }

    // public captcha(project: string, token: string): Observable<string> {
    //     return this.httpClient.post(
    //         // 'https://www.google.com/recaptcha/api/siteverify',
    //         `https://recaptchaenterprise.googleapis.com/v1/${project}/assessments`,
    //         {
    //             secret: '6Ld843IcAAAAAGBGrFVX-xuAb80d60uhNngd4gTT',
    //             response: token,
    //         },
    //         {
    //             responseType: 'text',
    //         },
    //     );
    // }
}
