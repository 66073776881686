import * as _ from 'lodash';
import { GenderType } from '../../_enums/genderType.enum';
import { Serializable } from './../deserialize.interface';

export class Customer implements Serializable<any> {
    id?: number;
    maskedId?: string;
    name?: string;
    surname?: string;
    address?: string;
    city?: string;
    country?: string;
    phone?: string;
    mobilePhone?: string;
    email?: string;
    post?: string;
    birthDate?: Date;
    birthDateString?: string;
    sendMail?: boolean = false;
    sendSms?: boolean = false;
    kzz?: string;
    emso?: string;
    taxNo?: string;
    medifitId?: number;
    createdFrom?: string;
    privacyPolicy?: boolean;
    gender: GenderType;
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.maskedId = input.maskedId;
        this.name = input.name;
        this.surname = input.surname;
        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.phone = input.phone;
        this.mobilePhone = input.mobilePhone;
        this.email = input.email;
        this.post = input.post;
        this.birthDate = input.birthDate;
        this.sendMail = input.sendMail;
        this.sendSms = input.sendSms;
        this.kzz = input.kzz;
        this.emso = input.emso;
        this.taxNo = input.taxNo;
        this.medifitId = input.medifitId;
        this.createdFrom = input.createdFrom;
        this.privacyPolicy = input.privacyPolicy;
        this.birthDateString = input.birthDateString;
        this.gender = input.gender;
        return this;
    }

    public getFullName(): string {
        // return 'xxxxx';
        // debugger;
        return `${this.name} ${this.surname}`;
    }
}
