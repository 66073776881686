import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PblVersionComponent } from './modules/public/pbl-version/pbl-version.component';
import { PublicComponent } from './modules/public/public.component';

const routes: Routes = [
    {
        path: 'reservations',
        loadChildren: () => import('./modules/reservations/reservations.module').then(m => m.ReservationsModule),
    },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    // { path: 'public/telemedicine', redirectTo: 'public/eposvet' },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'booking-widget',
                loadChildren: () => import('./modules/reservations/reservations.module').then(m => m.ReservationsModule),
            },
            {
                path: 'inquiries-widget',
                loadChildren: () => import('./modules/inquiries/inquiries.module').then(m => m.InquiriesModule),
            },
            {
                path: 'widget/:wkey',
                loadChildren: () => import('./modules/widget/widget.module').then(m => m.WidgetModule),
            },
            {
                //ea
                path: 'widget/:wkey/:ckey/:sckey/:skey',
                loadChildren: () => import('./modules/widget/widget.module').then(m => m.WidgetModule),
            },
            {
                //3phis external=true
                path: 'widget/:wkey/:ckey/:sckey/:skey/:bskey',
                loadChildren: () => import('./modules/widget/widget.module').then(m => m.WidgetModule),
            },
            {
                path: 'contractor-rating',
                loadChildren: () => import('./modules/contractor-rating/contractor-rating.module').then(m => m.ContractorRatingModule),
            },
            // {
            //     path: 'terms-and-conditions',
            //     loadChildren: () =>
            //         import('./modules/reservations/reservations.module').then((m) => m.ReservationsModule),
            // },
            {
                path: 'version',
                component: PblVersionComponent,
            },
        ],
    },
    {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
