import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-com-loading',
    templateUrl: './com-loading.component.html',
    styleUrls: ['./com-loading.component.scss'],
})
export class ComLoadingComponent implements OnInit {
    @Input() public message: string = 'Prosimo počakajte...'; //TODO
    constructor() {}

    ngOnInit() {}
}
