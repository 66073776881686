import { Injectable } from '@angular/core';
import { WindowRef } from './window-ref.service';

@Injectable({
    providedIn: 'root',
})
export class ParentIframeService {
    private iframeParent: any;

    constructor(private winRef: WindowRef) {}

    sendMessage(data: {}, replay = false) {
        try {
            // @ts-ignore
            this.winRef.nativeWindow.parentIFrame.sendMessage(data);
            return true;
        } catch (e) {
            console.info('No parent window');
            if (replay) {
                setTimeout(() => {
                    this.sendMessage(data, false);
                }, 2000);
            }
            return false;
        }
    }

    redirect(url: string): void {
        if (
            !this.sendMessage({
                type: 'redirect',
                content: url,
            })
        ) {
            this.winRef.nativeWindow.location.href = url;
        }
    }

    toggleScroll(value: boolean = false): void {
        this.sendMessage(
            {
                type: 'toggleScroll',
                content: value,
            },
            true,
        );
    }
}
